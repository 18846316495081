import { useContext, useEffect } from 'react'

import getConfig from 'next/config'
import { useCookies } from 'react-cookie'

import { AuthContext } from '~/context/AuthContext'
import { useGetAccountStateQuery } from '~/graphql/generated/schema'

const { publicRuntimeConfig } = getConfig()

type UseUpdateAccountStateOptions = {
  skip: boolean
}

const useUpdateAccountState = ({ skip }: UseUpdateAccountStateOptions = { skip: false }) => {
  const { loading, data } = useGetAccountStateQuery({ skip })
  const { setHasPremium, setIsFreeTrialEligible } = useContext(AuthContext)
  const [cookies] = useCookies(['featureFlags'])

  useEffect(() => {
    if (!skip && !loading) {
      const hasPremium =
        publicRuntimeConfig.premiumForAll || (cookies.featureFlags && cookies.featureFlags['grantPremium'] === true)
          ? true
          : data?.me?.hasPremium || false
      const isFreeTrialEligible = data?.me?.isFreeTrialEligible || false
      setHasPremium(hasPremium)
      setIsFreeTrialEligible(isFreeTrialEligible)
    }
  })
}

export default useUpdateAccountState
