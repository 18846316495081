import { useContext, useMemo } from 'react'

import useTranslation from 'next-translate/useTranslation'
import { useCookies } from 'react-cookie'

import { ActiveWorkoutCookie, PlayContext } from '~/constants'
import { AuthContext } from '~/context/AuthContext'
import { GameContext } from '~/context/GameContext'
import { MarketingContext } from '~/context/MarketingContext'
import GamesLibrary from '~/gameConfigs/GamesLibrary'
import { useGetTodaysAvailableWorkoutsQuery, useGetTodaysWorkoutQuery } from '~/graphql/generated/schema'
import { extractWorkoutDetails } from '~/utils/workoutUtils'

type UseTodaysWorkoutOptions = {
  skip?: boolean
}

const DefaultMode: WorkoutModeTypes = 'classic'
const LanguageMode: WorkoutModeTypes = 'language'

const useTodaysWorkout = ({ skip }: UseTodaysWorkoutOptions = { skip: false }) => {
  const { lang } = useTranslation()
  const isEnglish = lang === 'en'
  const { hasPremium } = useContext(AuthContext)
  const { playContext } = useContext(GameContext)
  const { logMarketingWorkoutComplete } = useContext(MarketingContext)
  const [cookies] = useCookies([ActiveWorkoutCookie])
  const activeWorkout = cookies[ActiveWorkoutCookie]

  const mode = (() => {
    // don't allow language mode for non english users
    if (activeWorkout?.mode === LanguageMode && !isEnglish) return DefaultMode
    if (hasPremium) return activeWorkout?.mode || DefaultMode
    return DefaultMode
  })()

  /**
   * useGetTodaysWorkoutQuery uses todaysWorkout,
   * passing a mode will, create and get the workout for that mode
   * if its already created, it will just get the workout
   *
   * not passing a mode will be treated like a classic workout
   */
  const { data, loading, error } = useGetTodaysWorkoutQuery({
    variables: {
      // pass all game slug to backend, backend will automatically filter out the games based on locale, mode & user preference etc.
      knownGameSlugs: GamesLibrary.map(({ slug }) => slug),
      mode: mode,
    },
    fetchPolicy: 'cache-and-network', // if mode is changed, we want to get the new workout details
    skip,
    onCompleted: (queryResult) => {
      if (playContext === PlayContext.Workout && queryResult?.me?.todaysWorkout) {
        const _workoutMeta = extractWorkoutDetails({ workoutData: queryResult.me.todaysWorkout, hasPremium })
        if (_workoutMeta) {
          const { isWorkoutComplete, allSlugs } = _workoutMeta
          if (isWorkoutComplete) {
            logMarketingWorkoutComplete(allSlugs as GameSlug[])
          }
        }
      }
    },
  })

  const workoutMeta = useMemo(() => {
    if (!data?.me?.todaysWorkout) return null
    return extractWorkoutDetails({ workoutData: data?.me?.todaysWorkout, hasPremium })
  }, [data?.me?.todaysWorkout, hasPremium])

  // do not return raw data here, instead return the extracted data:
  // workoutMeta - should be the details of active workout mode
  return { loading, error, workoutMeta }
}

export default useTodaysWorkout

type ExtractedWorkoutData = ReturnType<typeof extractWorkoutDetails>
export type WorkoutMetaNotNull = ExtractedWorkoutData
export type WorkoutMeta = ExtractedWorkoutData | null

/**
 * useGetTodaysAvailableWorkoutsQuery - returns all workouts that cre created for today,
 * it doesn't create the workout itself unlike useGetTodaysWorkoutQuery
 */
export const useAvailableWorkouts = () => {
  const { hasPremium } = useContext(AuthContext)
  const { data, loading, error } = useGetTodaysAvailableWorkoutsQuery({
    fetchPolicy: 'cache-and-network',
  })

  const allWorkoutModes = useMemo(() => {
    const workoutsFromBE = data?.me?.getAvailableWorkouts || []

    const getMeta = (mode: WorkoutModeTypes): WorkoutMeta => {
      const workout = workoutsFromBE.find((item) => item?.mode === mode)
      return workout ? extractWorkoutDetails({ workoutData: workout, hasPremium }) : null
    }

    const allWorkouts: Record<WorkoutModeTypes, WorkoutMeta> = {
      classic: getMeta('classic'),
      favorites: getMeta('favorites'),
      language: getMeta('language'),
      math: getMeta('math'),
      strengthen: getMeta('strengthen'),
      quick: getMeta('quick'),
    }
    return allWorkouts
  }, [data?.me?.getAvailableWorkouts, hasPremium])

  return { loading, error, allWorkoutModes }
}
