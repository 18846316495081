import { useGetStreaksQuery, Streak } from '~/graphql/generated/schema'
import dayjs from '~/libs/dayjs'

// converts to MS SINCE EPOCH
// const tempConverter = (dateString: string) => dayjs(dateString).valueOf()

const useStreakData = () => {
  // Get the date 7 days ago
  const [oneWeekAgo] = dayjs().subtract(7, 'day').startOf('day').toISOString().split('T')
  const yesterday = dayjs().subtract(1, 'day').startOf('day')

  const { loading, error, data } = useGetStreaksQuery({
    variables: { startDate: oneWeekAgo },
  })

  const streaksArray: Streak[] = data?.me.streakHistory?.streaks || []
  const latestStreak = streaksArray[streaksArray?.length - 1]
  const endOfLatestStreak = dayjs(latestStreak?.endDate) // if input is undefined, this will default to today's date

  const streakIsActive = latestStreak && yesterday.isSameOrBefore(endOfLatestStreak)

  const currentStreakLength: number = (streakIsActive && latestStreak?.length) || 0
  const bestStreakLength: number = data?.me.streakHistory?.bestStreak?.length || 0

  return { loading, error, currentStreakLength, bestStreakLength, streaksArray }
}

export default useStreakData
