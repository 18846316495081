import React, { useContext } from 'react'

import { Subheading2 } from '@lumoslabs/lumosity-storybook'
import { useSpring } from '@react-spring/core'
import { animated } from '@react-spring/web'
import styled from 'styled-components'

import WorkoutDonut from '~/components/workout/WorkoutDonut'
import { HeaderTypes } from '~/constants'
import { GameContext } from '~/context/GameContext'
import useTodaysWorkout, { WorkoutMetaNotNull } from '~/hooks/useTodaysWorkout'

export interface WorkoutHeaderProps {
  headerType: HeaderTypes
  workoutMeta: WorkoutMetaNotNull
}

const WorkoutHeader: React.FC<WorkoutHeaderProps> = ({ headerType, workoutMeta }) => {
  const { slug } = useContext(GameContext)
  const donutAnimation = useSpring({
    from: { transform: 'translate3d(250px,0,0) rotateZ(120deg)' },
    to: { transform: 'translate3d(0px,0,0) rotateZ(0deg)' },
  })

  const { pivotBySlug } = workoutMeta
  const currentGamePosition = pivotBySlug[slug]?.positionNumber ?? 0

  return (
    <WorkoutContainer>
      <animated.div style={{ ...donutAnimation, width: '40px', height: '40px' }}>
        <WorkoutDonut workoutMeta={workoutMeta} headerType={headerType} />
      </animated.div>
      <Counter>
        <CounterValue>{currentGamePosition}</CounterValue>
      </Counter>
    </WorkoutContainer>
  )
}

const WorkoutHeaderWrapper = ({ headerType }: { headerType: HeaderTypes }) => {
  const skip = ![HeaderTypes.postWorkoutGame, HeaderTypes.preWorkoutGame, HeaderTypes.preFitTestGame].includes(
    headerType,
  )
  const { workoutMeta } = useTodaysWorkout({ skip })

  return workoutMeta ? <WorkoutHeader headerType={headerType} workoutMeta={workoutMeta} /> : null
}

const WorkoutContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral[100]};
  border-radius: 40px;
  display: flex;
  align-items: center;
  width: 80px;
  background: ${({ theme }) => theme.colorTokens.surface['surface-default']};
`

const Counter = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.neutral[400]};
  padding: 8px;
`
const CounterValue = styled(Subheading2)`
  display: inline-block;
`

export default WorkoutHeaderWrapper
