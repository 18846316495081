import React from 'react'

import { Caption1, Heading3, MicroText1 } from '@lumoslabs/lumosity-storybook'
import styled from 'styled-components'

import QueryLoader from '~/components/QueryLoader'
import StreakCalendar from '~/components/streaks/StreakCalendar'
import { CardShadowRadius } from '~/components/ui/sharedStyledComponents'
import SkeletonLoader from '~/components/ui/SkeletonLoader'
import useStreakData from '~/hooks/useStreakData'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'

const StreakCard = (): JSX.Element => {
  const t = useTranslationForNamespace('common')
  const { currentStreakLength, bestStreakLength, loading, error } = useStreakData()

  return (
    <QueryLoader loading={loading} error={error} data={!loading} LoadingComponent={<Skeleton />}>
      <CardContainer>
        <CardTitle>{t('streaks.yourStreaks')}</CardTitle>
        <StreakUnderline />
        <CalendarContainer>
          <StreakCalendar inkBackground={false} />
        </CalendarContainer>
        <StreakLengthsContainer>
          <Row>
            <Heading3>{t('streaks.day', { count: currentStreakLength })}</Heading3>
            <Heading3>{t('streaks.day', { count: bestStreakLength })}</Heading3>
          </Row>
          <Row>
            <StreakLabelsText>{t('streaks.currentStreak')}</StreakLabelsText>
            <StreakLabelsText>{t('streaks.bestStreak')}</StreakLabelsText>
          </Row>
        </StreakLengthsContainer>
      </CardContainer>
    </QueryLoader>
  )
}

const Skeleton = styled(SkeletonLoader)`
  width: 320px;
  height: 240px;
`

const CardContainer = styled(CardShadowRadius)`
  width: 326px;
  height: 241px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[100]};
  box-shadow: ${({ theme }) => theme.effects.boxShadow.outset[1]};
`

const CardTitle = styled(MicroText1)`
  color: ${({ theme }) => theme.colors.neutral[300]};
  padding: 18px 18px 8px;
  display: block;
`

const StreakUnderline = styled.hr`
  height: 1px;
  background-color: ${(props) => props.theme.colors.neutral[100]};
  border: none;
`

const StreakLengthsContainer = styled.div`
  margin: 22px 10px;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 1fr;
  text-align: center;
  align-items: center;
  color: ${(props) => props.theme.colors.neutral[400]};
`

const Row = styled.div`
  display: flex;
  > * {
    flex: auto;
  }
`

const StreakLabelsText = styled(Caption1)`
  color: ${(props) => props.theme.colors.neutral[300]};
  margin-top: 8px;
  margin-right: 10px;
`

const CalendarContainer = styled.div`
  color: ${(props) => props.theme.colors.neutral[400]};
`

export default StreakCard
