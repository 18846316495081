import styled from 'styled-components'
import Modal from 'styled-react-modal'

import Close from '~/images/icons/SystemOutlined/Close.svg'
import { theme } from '~/styles/theme'

/** @deprecated */
const StyledModal = Modal.styled`
  background: ${(props: { theme: typeof theme }) => props.theme.colors.background.card};
  border-radius: 16px;
  margin: 0 auto;
  min-width: 342px;
  padding: 56px;
  max-height: 75%;
  max-width: 50%;
  @media (min-width: 768px) {
    width: 800px;
  }
  overflow-y: auto;
  position: relative;
`

export default StyledModal

/**
 * Creating this modal component and its content scrollable along with the scroll-track to be inside the modal.
 * In the current implementation of the modal, when hovered over the scroll-track,
 * the modal's border-radius is not applied to the scroll-track making one side of the modal
 * as a sharp edge.
 * TODO: Slowly move to using this modal and its content for all modals in the app.
 */
export const StyledModalContainer = Modal.styled`
  background: ${(props: { theme: typeof theme }) => props.theme.colors.background.card};
  border-radius: 8px;
  min-width: 342px;
  max-height: 75%;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    width: 800px;
  };
  overflow: hidden;
  position: relative;
`

export const StyledModalContentScrollable = styled.div`
  overflow: auto;
  padding: 48px;
  flex-grow: 1;
`

export const ModalCloseButton = styled(Close).attrs({ title: 'Close' })`
  position: absolute;
  cursor: pointer;
  top: 30px;
  right: 35px;
  font-size: 32px;
  width: 32px;
  height: 32px;
`
