import { GetTodaysWorkoutQuery, GetTodaysAvailableWorkoutsQuery } from '~/graphql/generated/schema'

// Extract Workout type from GetTodaysWorkoutQuery
export type WorkoutFromTodaysWorkoutQuery = GetTodaysWorkoutQuery['me']['todaysWorkout']

// Extract Workout type from GetTodaysAvailableWorkoutsQuery
export type WorkoutFromAvailableWorkoutsQuery = NonNullable<
  NonNullable<GetTodaysAvailableWorkoutsQuery['me']['getAvailableWorkouts']>[number]
>

export const extractWorkoutDetails = ({
  workoutData,
  hasPremium,
}: {
  workoutData: WorkoutFromTodaysWorkoutQuery | WorkoutFromAvailableWorkoutsQuery
  hasPremium: boolean
}) => {
  const slots = hasPremium
    ? {
        slot1: workoutData.slots.slot1,
        slot2: workoutData.slots.slot2,
        slot3: workoutData.slots.slot3,
        slot4: workoutData.slots.slot4,
        slot5: workoutData.slots.slot5,
      }
    : {
        slot1: workoutData.slots.slot1,
        slot2: workoutData.slots.slot2,
        slot5: workoutData.slots.slot5,
      }

  const allSlots = Object.values(slots)
  const allSlugs: Array<string> = []

  const lastPlayedGames: WorkoutGame[] = []
  const nextUnplayedGames: WorkoutGame[] = []

  // pivotBySlug is quick access for mapping slug to the slot
  const pivotBySlug: Record<string, WorkoutGame & { positionNumber: number }> = {}

  allSlots.forEach((slot, index) => {
    allSlugs.push(slot.game.slug)
    // positionNumber is 1 based, used to show the position of the game by the slug
    pivotBySlug[slot.game.slug] = { ...slot, positionNumber: index + 1 }
    if (slot.lastGamePlay) {
      lastPlayedGames.push(slot)
    }
    if (!slot.lastGamePlay) {
      nextUnplayedGames.push(slot)
    }
  })

  const totalGamesCount = allSlots.length
  const totalGamePlayed = lastPlayedGames.length
  // Prevent the remaining game count going below 0 when the sub status goes from Premium to Free
  const _remainingGameCount = totalGamesCount - totalGamePlayed
  const remainingGameCount = _remainingGameCount < 0 ? 0 : _remainingGameCount

  // allPremiumSlots is an array of all slots in the workout, irrespective of hasPremium, i,e  5 slots.
  // used in rendering donut
  const allPremiumSlots = [
    workoutData.slots.slot1,
    workoutData.slots.slot2,
    workoutData.slots.slot3,
    workoutData.slots.slot4,
    workoutData.slots.slot5,
  ]

  return {
    mode: workoutData.mode as WorkoutModeTypes,
    algorithmVersion: workoutData.algorithmVersion || '',
    workoutId: workoutData.id ?? '',

    allSlugs,
    totalGamePlayed,

    isFirstGame: totalGamePlayed === 0,
    isLastGame: totalGamePlayed === totalGamesCount - 1,
    isWorkoutStarted: totalGamePlayed > 0,
    isWorkoutComplete: totalGamePlayed === totalGamesCount,

    nextUnplayedGame: nextUnplayedGames[0],
    nextUnplayedGames,

    lastPlayedGame: lastPlayedGames[lastPlayedGames.length - 1],
    lastPlayedGames,

    totalGamesCount,
    remainingGameCount,
    pivotBySlug,
    allSlots,

    allPremiumSlots,
  }
}
